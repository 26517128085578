<template>
  <div>
    <Card :bordered="false" dis-hover class="ivu-mt">
      <div class="mb20">
        <Tabs v-model="currentTab" @on-click="handleChangeTab">
          <TabPane :label="item.label" :name="item.value.toString()" v-for="(item, index) in headerList" :key="index" />
        </Tabs>
      </div>
      <Row type="flex" class="l-m-b20" v-if="currentTab == 1 || currentTab == 3">
        <Col v-bind="grid">
          <Button
            v-if="currentTab == 1"
            v-auths="[`${$config.uniquePrefix}AppAppletTemplateSync`]"
            icon="md-list"
            type="success"
            @click="handleRoutineTemplate('users')"
            style="margin-left: 20px">同步用户小程序订阅消息</Button>
          <Button
            v-if="currentTab == 3"
            v-auths="[`${$config.uniquePrefix}AppAppletTemplateSync`]"
            icon="md-list"
            type="success"
            @click="handleRoutineTemplate('technician')"
            style="margin-left: 20px">同步技工小程序订阅消息</Button>
          <Button
            v-auths="[`${$config.uniquePrefix}AppWechatTemplateSync`]"
            icon="md-list"
            type="success"
            @click="handleWechatTemplate"
            style="margin-left: 20px">同步微信模版消息</Button>
        </Col>
      </Row>
      <Alert v-if="currentTab == 1 || currentTab == 3">
        <template slot="desc">
          1、公众号：登录微信公众号后台，选择模版消息，将模版消息的所在行业修改副行业为《其他/其他》<br />
          2、小程序：登录微信小程序后台，基本设置，服务类目增加《生活服务> 百货/超市/便利店》
        </template>
      </Alert>
      <Button class="l-m-t15" icon="md-add" @click="createModal = true">添加模板消息</Button>
      <Table
        :columns="currentTab == 1 ? columns : (currentTab == 2 ? columns1 : columns2)"
        :data="dataList"
        ref="table"
        class="l-m-t25"
        :loading="loading"
        highlight-row
        no-userFrom-text="暂无数据"
        no-filtered-userFrom-text="暂无筛选结果">
        <template slot-scope="{ row }" slot="name">
          <span class="table">
            {{ row.name }}
          </span>
        </template>
        <template slot-scope="{ row }" slot="title">
          <span class="table">{{ row.title }}</span>
        </template>
        <template
          slot-scope="{ row }"
          v-for="item in ['is_system', 'is_wechat', 'is_routine', 'is_sms', 'is_ent_wechat']"
          :slot="item">
          <div v-if="item === 'is_ent_wechat' && currentTab == 1" :key="index">--</div>
          <i-switch
            v-model="row[item]"
            :value="row[item]"
            :true-value="1"
            :false-value="2"
            @on-change="handleChangeSwitch(row, item)"
            size="large"
            v-if="row[item] > 0 && currentTab !== 1">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </template>
        <template slot-scope="{ row, index }" slot="setting">
          <span class="setting btn" @click="handleSetting(row)">设置</span>
          <Divider type="vertical" />
          <span class="setting btn l-color-error" @click="handleDelete(row, '删除模板', index)">删除</span>
        </template>
      </Table>
    </Card>
    <!-- 添加模板消息模态框 -->
    <Modal
      v-model="createModal"
      title="添加模板消息"
      footer-hide
      @on-cancel="createModal = false">
      <Form ref="formValidate" :model="formItem" :rules="ruleValidate" :label-width="100">
        <FormItem label="通知类型" prop="name">
          <Input v-model="formItem.name" placeholder="请输出消息通知类型"></Input>
        </FormItem>
        <FormItem label="通知场景说明" prop="title">
          <Input type="textarea" v-model="formItem.title" placeholder="请输入通知场景说明"></Input>
        </FormItem>
        <FormItem label="消息类型">
          <CheckboxGroup v-model="formItem.checkbox">
            <Checkbox label="is_system">站内信</Checkbox>
            <Checkbox label="is_app">APP推送</Checkbox>
            <Checkbox label="is_wechat">公众号模板消息</Checkbox>
            <Checkbox label="is_routine">小程序订阅消息</Checkbox>
            <Checkbox label="is_sms">发送短信</Checkbox>
            <Checkbox label="is_ent_wechat">企业微信群通知</Checkbox>
          </CheckboxGroup>
        </FormItem>
        <FormItem>
          <Button @click="handleTemplateCreate('formValidate')">提交</Button>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import mixins from '@/mixins'
import {
  getNotificationListApi,
  editNoticeStatusApi,
  getNotificationSave,
  routineSyncTemplateApi
} from '@/api/setting'

export default {
  name: 'index',
  mixins: [mixins],
  data () {
    return {
      currentTab: '1',
      headerList: [
        { label: '通知会员', value: '1' },
        { label: '通知平台', value: '2' },
        { label: '通知司机', value: '3' }
      ],

      dataList: [],
      columns: [
        { title: 'ID', key: 'id', align: 'center', width: 60 },
        { title: '通知类型', slot: 'name', align: 'center', width: 200 },
        { title: '通知场景说明', slot: 'title', align: 'center', minWidth: 200 },
        { title: '站内信', slot: 'is_system', align: 'center', minWidth: 100 },
        { title: '公众号模板', slot: 'is_wechat', align: 'center', minWidth: 100 },
        { title: '小程序订阅', slot: 'is_routine', align: 'center', minWidth: 100 },
        { title: '发送短信', slot: 'is_sms', align: 'center', minWidth: 100 },
        { title: '设置', slot: 'setting', width: 150, align: 'center' }
      ],
      columns1: [
        { title: 'ID', key: 'id', align: 'center', width: 60 },
        { title: '通知类型', slot: 'name', align: 'center', width: 200 },
        { title: '通知场景说明', slot: 'title', align: 'center', minWidth: 200 },
        { title: '站内信', slot: 'is_system', align: 'center', minWidth: 100 },
        { title: '公众号模板', slot: 'is_wechat', align: 'center', minWidth: 100 },
        { title: '发送短信', slot: 'is_sms', align: 'center', minWidth: 100 },
        { title: '企业微信', slot: 'is_ent_wechat', align: 'center', minWidth: 100 },
        { title: '设置', slot: 'setting', width: 150, align: 'center' }
      ],
      columns2: [
        { title: 'ID', key: 'id', align: 'center', width: 60 },
        { title: '通知类型', slot: 'name', align: 'center', width: 200 },
        { title: '通知场景说明', slot: 'title', align: 'center', minWidth: 200 },
        { title: '站内信', slot: 'is_system', align: 'center', minWidth: 100 },
        { title: '公众号模板', slot: 'is_wechat', align: 'center', minWidth: 100 },
        { title: '小程序订阅', slot: 'is_routine', align: 'center', minWidth: 100 },
        { title: '发送短信', slot: 'is_sms', align: 'center', minWidth: 100 },
        { title: '设置', slot: 'setting', width: 150, align: 'center' }
      ],

      createModal: true,
      formItem: {
        name: '',
        title: '',
        checkbox: [],
        is_system: 0,
        is_app: 0,
        is_wechat: 0,
        is_routine: 0,
        is_sms: 0,
        is_ent_wechat: 0
      },
      ruleValidate: {
        name: [{ required: true, message: '请输出消息通知类型', trigger: 'blur' }],
        title: [{ required: true, message: '请输入通知场景说明', trigger: 'blur' }]
      }
    }
  },
  // 支柱道具属性
  props: {},
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted () {
    this.handleChangeTab(this.currentTab)
  },
  // 方法集合
  methods: {
    // 处理Tabs选择
    handleChangeTab (data) {
      getNotificationListApi(data).then(res => {
        this.dataList = res.data
      }).catch(err => {
        console.log(err)
      })
    },
    // 添加模板消息
    handleTemplateCreate (name) {
      if (this.formItem.checkbox.length !== 0) {
        this.formItem.checkbox.forEach(el => {
          this.formItem[el] = 2
        })
      } else {
        this.$Message.error('请至少选择一项开启')
        return
      }
      this.$refs[name].validate((valid) => {
        if (valid) {
          getNotificationSave(this.formItem).then(res => {
            this.$Message.success('Success!')
          }).catch(err => {
            this.$Message.error(err)
          })
        } else {
          this.$Message.error('必填项不可为空!')
        }
      })
    },
    // 处理同步小程序模板
    handleRoutineTemplate (type) {
      routineSyncTemplateApi(type).then((res) => {
        this.$Message.success(res.msg)
        this.handleChangeTab(this.currentTab)
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    // 处理同步微信模板
    handleWechatTemplate () {},
    // 处理状态修改
    handleChangeSwitch (row, item) {
      editNoticeStatusApi(item, row[item], row.id).then((res) => {
        this.$Message.success(res.msg)
      }).catch((err) => {
        this.$Message.error(err.msg)
      })
    },
    // 处理设置
    handleSetting (row) {
      this.$router.push({
        path: this.$config.routePre + '/setting/notification/edit?id=' + row.id
      })
    },
    // 处理删除
    handleDelete (row, tit, num) {
      let delfromData = {
        title: tit,
        num: num,
        url: `setting/notification/del/${row.id}`,
        method: 'DELETE',
        ids: ''
      }
      this.$overallModal(delfromData).then((res) => {
        this.$Message.success(res.msg)
        this.handleChangeTab(this.currentTab)
        this.dataList.splice(num, 1)
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    }
  },
  // 生命周期 - 更新之前
  beforeUpdate () {
  },
  // 生命周期 - 更新之后
  updated () {
  },
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate () {
  },
  // 生命周期 - 挂载之前
  beforeMount () {
  },
  // 生命周期 - 销毁之前
  beforeDestroy () {
  },
  // 生命周期 - 销毁完成
  destroyed () {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated () {
  },
  // 离开的时候触发
  deactivated () {
  }
}
</script>

<style scoped>
.message /deep/ .ivu-table-header table {
  /* border-top: 1px solid #e8eaec !important;
  border-left: 1px solid #e8eaec !important; */
}
.message /deep/ .ivu-table-header thead tr th {
  padding: 8px 16px;
}
.message /deep/ .ivu-tabs-tab {
  border-radius: 0 !important;
}
.table-box {
  padding: 20px;
}
.is-table {
  display: flex;
  /* justify-content: space-around; */
  justify-content: center;
}
.btn {
  padding: 6px 12px;
  cursor: pointer;
  color: #2d8cf0;
  font-size: 10px;
  border-radius: 3px;
}
.is-switch-close {
  background-color: #504444;
}
.is-switch {
  background-color: #eb5252;
}
.notice-list {
  background-color: #308cf5;
  margin: 0 15px;
}
.table {
  padding: 0 18px;
}
</style>
